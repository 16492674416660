import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>
                    <span>N</span>
                    <span>O</span>
                    <span>B</span>
                    <span>E</span>
                    <span>H</span>
                    <span className="cursor">_</span>
                </h1>
            </header>
        </div>
    );
}


export default App;